import industryGif from '../assets/images/animations/industries.gif'
import teamGif from '../assets/images/animations/team.gif'
import productivityGif from '../assets/images/animations/productivity.gif'

export default function Services() {
  const services = [
    {
      image: industryGif,
      heading: 'Empowering Diverse Industries with Cutting-Edge IT Solutions',
      description: `We take pride in delivering top-notch IT services that transcend
                industry boundaries. With a proven track record of excellence,
                we have successfully catered to a diverse range of sectors,
                providing innovative solutions that drive growth and elevate
                operational efficiency.
                `,
      button: 'Explore',
      url: '/product/portfolio/',
    },
    {
      image: teamGif,
      heading:
        ' Transforming Ideas into Exceptional Products Through Collaborative Innovation',
      description: `Our team is dedicated to seamlessly integrating with your
                    organization, working hand-in-hand to turn your visionary ideas
                    into tangible, market-ready products. We understand that the
                    journey from concept to product is unique for every business,
                    and that's why we offer a dynamic and adaptable approach.
                    `,
      button: 'Product Stories',
      url: '/product/stories/',
    },
    {
      image: productivityGif,
      heading:
        'Unleashing MNC Productivity through Tailored AI and Machine Learning Solutions',
      description: `Our team of AI and machine learning experts collaborates with
                    MNCs to understand the unique challenges they face in their
                    operations. Leveraging the power of artificial intelligence, we
                    custom-build solutions that enhance efficiency, streamline
                    workflows, and unlock new dimensions of productivity.
                    `,
      button: 'Explore',
      url: '/products/',
    },
  ]
  return (
    <section id="services" className='py-5 lg:py-10'>
      <div className="w-full">
        <div className="flex flex-col gap-10 lg:gap-16 px-5 lg:px-32">
          {services.map((service, index) => {
            return (
              <div
                className={`flex gap-4 lg:gap-10 flex-col shadow-md lg:shadow-none p-5 lg:p-0 bg-white lg:mix-blend-multiply rounded-md items-center justify-center ${
                  index % 2 !== 0 ? 'lg:flex-row-reverse' : 'lg:flex-row'
                }`}
              >
                <div className="w-full lg:w-1/2">
                  <img
                    src={service.image}
                    alt={service.heading}
                    loading="lazy"
                    className="block mix-blend-multiply"
                  />
                </div>
                <div className="w-full lg:w-1/2 flex flex-col gap-4 lg:gap-10">
                  <h3 className="font-bold text-md lg:text-3xl">
                    {service.heading}
                  </h3>
                  <p className="text-sm lg:leading-9 md:text-lg lg:text-lg">
                    {service.description}
                  </p>
                  {/* <a
                    href={service.url}
                    className="px-4 py-2 bg-[#40E0D0] border-[1px] transition-all transform ease-in-out border-[#40E0D0] hover:text-[#40E0D0] hover:bg-white hover:border-[1px] text-xs lg:text-lg w-fit text-white rounded-md"
                  >
                    {service.button}
                  </a> */}
                </div>
              </div>
            )
          })}
        </div>
      </div>
    </section>
  )
}
