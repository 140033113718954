import main from '../assets/svg/main.bg.svg'

export default function Hero() {
  return (
    <section className="flex lg:gap-4 text-center w-full lg:text-justify">
      <div className="lg:pl-20 lg:pt-10 w-full">
        <h2 className="text-6xl lg:text-7xl font-bold mt-10 text-[#40E0D0]">
          Reliable<span className="text-black">,</span>
        </h2>
        <h3 className="text-6xl lg:text-7xl font-bold mt-10">
          Smart<span className="text-[#40E0D0]">,</span>
        </h3>
        <h4 className="text-6xl lg:text-7xl font-bold mt-10 text-[#40E0D0]">
          Innovative
        </h4>
        <h5 className="text-6xl lg:text-7xl font-bold mt-10">
          IT Solutions<span className="text-[#40E0D0]">.</span>
        </h5>
      </div>
      <div className="w-full hidden lg:block">
        <img
          src={main}
          className="mt-20"
          alt="Futurestic: Reliable, Smart and Innovative IT Solutions"
        ></img>
      </div>
    </section>
  )
}
