import { Email, MapsHomeWorkSharp } from '@mui/icons-material'
import location from '../assets/svg/location.svg'
import LocalPhoneIcon from '@mui/icons-material/LocalPhone'

export default function ContactForm() {
  return (
    <section id="contact" className='py-10 px-10'>
      <div className="flex flex-col gap-5 items-center">
        <h1 className="text-2xl lg:text-5xl mt-10">Contact Us</h1>
        <p>
        With Futurestic, your journey to excellence starts today
        </p>
        <div className="flex flex-row gap-10 items-center">
          <div className="p-10 hidden lg:flex lg:items-center lg:justify-center">
            <img src={location} alt="location" className="h-[400px]" />
          </div>
          <div className="flex flex-col gap-5 lg:gap-10">
            <div className="flex flex-row gap-5 items-center">
              <LocalPhoneIcon
                color="#40E0D0"
                className="bg-[#40E0D0] text-white p-2 rounded-full"
                fontSize="large"
              />
              <p className="text-md lg:text-xl">+91 9625645572</p>
            </div>
            <div className="flex flex-row gap-5 items-center">
              <Email
                color="#40E0D0"
                className="bg-[#40E0D0] text-white p-2 rounded-full"
                fontSize="large"
              />
              <p className="text-md lg:text-xl">contact@futurestic.co.in</p>
            </div>
            <div className="flex flex-row gap-5 items-center">
              <MapsHomeWorkSharp
                color="#40E0D0"
                className="bg-[#40E0D0] text-white p-2 rounded-full"
                fontSize="large"
              />
              <p className="text-md lg:text-xl">WeWork, Blue One Square, Gurugram</p>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
