import { useEffect, useRef, useState } from 'react'
import { PopupButton } from 'react-calendly'

export default function Header({
  activeSection
}) {
  const [openMenu, setOpenMenu] = useState(false);
  const [scrolled, setScrolled] = useState(false);
  const drawerRef = useRef(null);

  const Toggle = () => {
    setOpenMenu(!openMenu);
    document.body.classList.toggle('noscroll', openMenu);
  };

  const handleScroll = () => {
    if (window.scrollY > 0) {
      setScrolled(true);
    } else {
      setScrolled(false);
    }
  };

  const handleClickOutside = (event) => {
    if (
      drawerRef.current &&
      !drawerRef.current.contains(event.target) &&
      !event.target.classList.contains('popup-button')
    ) {
      setOpenMenu(false);
      document.body.classList.remove('overflow-hidden');
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside);

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [openMenu]);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [openMenu]);

  return (
    <section>
      <div className={`hidden h-[100px] top-0 z-50 transition-all ease-in-out delay-50 duration-300 w-full lg:grid lg:grid-flow-col px-10 py-4 items-center ${scrolled ? 'fixed bg-white shadow-md' : 'relative'}`}>
        <a
          href="/"
          className="text-[#40E0D0] text-4xl text-center lg:text-initial"
        >
          FUTURESTIC
        </a>
        <div className="mx-auto hidden lg:block">
          <ul className="flex gap-10 text-lg text-right items-center">
            <li
              className={
                activeSection === ''
                  ? 'selected'
                  : "relative before:content-[''] before:absolute before:block before:w-full before:h-[2px] before:bottom-0 before:left-0 before:bg-black before:hover:scale-x-100 before:scale-x-0 before:origin-top-left before:transition before:ease-in-out before:duration-300"
              }
            >
              <a href="/">Home</a>
            </li>
            <li
              className={
                activeSection === 'services'
                  ? 'selected'
                  : "relative before:content-[''] before:absolute before:block before:w-full before:h-[2px] before:bottom-0 before:left-0 before:bg-black before:hover:scale-x-100 before:scale-x-0 before:origin-top-left before:transition before:ease-in-out before:duration-300"
              }
            >
              <a href="#services">Services</a>
            </li>
            <li
              className={
                activeSection === 'openasapp'
                  ? 'selected'
                  : "relative before:content-[''] before:absolute before:block before:w-full before:h-[2px] before:bottom-0 before:left-0 before:bg-black before:hover:scale-x-100 before:scale-x-0 before:origin-top-left before:transition before:ease-in-out before:duration-300"
              }
            >
              <a href="#openasapp">OpenAsApp Partner Program</a>
            </li>
            <li
              className={
                activeSection === 'contact'
                  ? 'selected'
                  : "relative before:content-[''] before:absolute before:block before:w-full before:h-[2px] before:bottom-0 before:left-0 before:bg-black before:hover:scale-x-100 before:scale-x-0 before:origin-top-left before:transition before:ease-in-out before:duration-300"
              }
            >
              <a href="#contact">Contact</a>
            </li>
            <li>
              <PopupButton
              className="text-center popup-button hidden w-fit py-5 px-5 lg:block bg-[#40E0D0] p-2 text-md text-center text-white rounded-md"
              rootElement={document.getElementById('root')}
              url="https://calendly.com/futurestic/discovery-call"
              text="Book Appointment"
              onClick={(event) => {
                event.stopPropagation();
              }}
            />
            </li>
          </ul>
        </div>
      </div>
      <div className={`drawer px-10 py-20 ${openMenu ? 'open' : ''}`}>
        <nav>
          <ul className=" w-full text-left mx-auto text-white">
            <li className={activeSection === '' ? 'selectedMobile mt-5' : 'mt-5'}>
              <a href="/">Home</a>
            </li>
            <li
              className={activeSection === 'openasapp' ? 'selectedMobile mt-5' : 'mt-5'}
            >
              <a href="#openasapp">OpenAsApp Partner Program</a>
            </li>
            <li
              className={
                activeSection === 'services' ? 'selectedMobile mt-5' : 'mt-5'
              }
            >
              <a href="#services">Services</a>
            </li>
            <li
              className={
                activeSection === 'contact' ? 'selectedMobile mt-5' : 'mt-5'
              }
            >
              <a href="#contact">Contact</a>
            </li>
            <li className="mt-10 bg-[#40E0D0] p-2 text-md text-center text-white rounded-md">
              <PopupButton
                rootElement={document.getElementById('root')}
                url="https://calendly.com/futurestic/discovery-call"
                text="Book Appointment"
                onClick={(event) => {
                  event.stopPropagation();
                }}
              />
            </li>
          </ul>
        </nav>
      </div>
      <div className={`flex top-0 pb-5 w-full z-50 ${openMenu ? 'bg-black':'bg-white'} shadow-sm lg:hidden pl-5 pr-5 pt-5 ${scrolled ? 'fixed':'relative'}`}>
        <div
          className="lg:hidden flex items-center z-50"
          ref={drawerRef}
        >
          {/* Hamburger Icon */}
          <div
            className="w-6 h-6 cursor-pointer flex flex-col justify-between transition-all duration-300 z-50"
            onClick={Toggle}
          >
            <div
              className={`${
                openMenu ? 'bg-white' : 'bg-black'
              } h-[2px] w-6 mb-1.5 transform ${
                openMenu ? 'rotate-45 translate-y-2 delay-100' : 'translate-x-0'
              } transition-all ease-in-out`}
            ></div>
            <div
              className={`${
                openMenu ? 'bg-white' : 'bg-black'
              } h-[2px] w-4 mb-1.5 opacity-0 ${
                openMenu ? 'opacity-0' : 'opacity-100 delay-100'
              } transition-all ease-in-out`}
            ></div>
            <div
              className={`${
                openMenu ? 'bg-white' : 'bg-black'
              } h-[2px] w-6 mb-1.5 transform ${
                openMenu
                  ? '-rotate-45 -translate-y-2 delay-100'
                  : 'translate-x-0'
              } transition-all ease-in-out`}
            ></div>
          </div>
        </div>
        <a href="/" className="text-[#40E0D0] mx-auto text-center w-fit text-3xl text-center">
          FUTURESTIC
        </a>
      </div>
    </section>
  )
}
