import React from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import Home from './pages/home'

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
  <Router>
    <div className="bg-opacity-5 bg-gradient-to-r from-white to-blue-50">
      <Routes>
        <Route exact path="/" element={<Home />}></Route>
      </Routes>
    </div>
  </Router>,
)
