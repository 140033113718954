import { useEffect, useState } from 'react'
import Clients from '../components/clients'
import ContactForm from '../components/contact.form'
import FloatingButton from '../components/floating.button'
import Hero from '../components/hero'
import OpenAsAppPartner from '../components/openasapp.partner'
import Services from '../components/services'
import Testimonials from '../components/testimonials'
import Header from '../components/header'
import Footer from '../components/footer'

export default function Home() {
  const [currentSection, setCurrentSection] = useState('');

  useEffect(() => {
    const handleScroll = () => {
      const sections = document.querySelectorAll('section');
      sections.forEach(section => {
        const top = section.offsetTop;
        const height = section.clientHeight;
        if (window.pageYOffset >= top && window.pageYOffset < top + height) {
          setCurrentSection(section.id);
          window.history.pushState(null, '', `/#${section.id}`);
        }
      });
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
  return (
    <>
      <Header activeSection={currentSection} />
      <Hero />
      <Clients />
      <FloatingButton />
      <Services />
      <OpenAsAppPartner />
      <Testimonials />
      <ContactForm/>
      <Footer/>
    </>
  )
}
