import openasapp from '../assets/images/openasapp.webp'
import { PopupButton } from 'react-calendly'

const OpenAsAppPartner = () => {
  return (
    <section id='openasapp' className="bg-[#174759] mt-[150px] px-10 py-5 lg:py-10 lg:px-32">
      <div className="flex items-center flex-col-reverse lg:flex-row gap-10">
        <div className="lg:w-1/2 flex flex-col gap-4">
          <img
          alt='OpenAsApp'
            className="w-[150px]"
            src="https://openasapp.com/wp-content/uploads/2022/10/horizontal-white-logo-solid.svg"
          ></img>
          <p className="text-[#40E0D0] text-xs lg:text-md">
            Trusted OpenAsApp Partner
          </p>
          <h4 className="text-white text-sm lg:text-xl">
            Our partnership with OpenAsApp delivers rapid, robust solutions for
            businesses. With a proven track record of 50+ projects worldwide, we
            specialize in transforming spreadsheets into dynamic financial
            applications.
            <br />
            <br />
            This collaboration exemplifies our commitment to providing
            professional-grade solutions that elevate business efficiency and
            performance.
          </h4>
          <div className="bg-[#40E0D0] text-white text-sm lg:text-lg rounded-full border-[1px] border-[#40E0D0] py-2 px-4 w-fit cursor-pointer hover:bg-white hover:text-[#40E0D0]">
            <PopupButton
                rootElement={document.getElementById('root')}
                url="https://calendly.com/futurestic/discovery-call"
                text="Book Call"
            />
          </div>
        </div>
        <div>
          <img src={openasapp} alt='OpenAsApp : Turn Spreadsheets into Application'></img>
        </div>
      </div>
    </section>
  )
}

export default OpenAsAppPartner
