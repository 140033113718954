import React, { useState } from 'react'
import testimonial from '../assets/svg/testimonial.svg'
import peter from '../assets/images/testimonials/peter.jpeg'
import quote from '../assets/svg/quotes.svg'
import vidal from '../assets/images/testimonials/vidal.jpeg'
import pratham from '../assets/images/testimonials/pratham.jpeg'
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';


const testimonialData = [
  {
    id: 1,
    text: `Futurestic proves to be an outstanding team player with a remarkable ability to communicate effectively. Their passion lies in achieving project excellence, consistently raising the bar across diverse industries. Their commitment to delivering high-quality results makes them a valuable and dependable contributor to our company's success.`,
    author: 'Peter Lo',
    designation: 'Digital Strategy and Innovation Director',
    company:'ITPEnergised',
    image: peter,
  },
  {
    id: 2,
    text: `Futurestic played a pivotal role in crafting my e-commerce platform. Their expertise and dedication turned my vision into reality.The end result surpassed expectations, driving increased customer engagement and sales. I highly recommend Futurestic for their outstanding e-commerce development services and commitment to client success.`,
    author: 'Vidal Fernandez',
    designation: 'Founder',
    company:'Lalola',
    image: vidal,
  },
  {
    id: 3,
    text: `Working with Futurestic never brought the feeling of having our work outsourced. The founder himself addressed the needs of our company as if he were an integral part of the project. The ease and comfort of obtaining the deliverables were at par with its quality.`,
    author: 'Pratham Ahuja',
    designation: 'Founder',
    company:'Evenlay',
    image: pratham,
  },
]

const Testimonials = () => {
  const [currentTestimonial, setCurrentTestimonial] = useState(0)

  const handlePrevTestimonial = () => {
    setCurrentTestimonial((prevIndex) =>
      prevIndex === 0 ? testimonialData.length - 1 : prevIndex - 1,
    )
  }

  const handleNextTestimonial = () => {
    setCurrentTestimonial((prevIndex) =>
      prevIndex === testimonialData.length - 1 ? 0 : prevIndex + 1,
    )
  }

  return (
    <section id="testimonials" className="py-2 px-5 lg:py-10 lg:px-32 flex">
      <div className="w-1/2 hidden lg:block">
        <img
          src={testimonial}
          alt={testimonialData[currentTestimonial].company}
          className="lg:block mix-blend-multiply"
        ></img>
      </div>
      <div className="p-4 w-full lg:w-1/2 flex flex-col gap-5 relative">
        <img src={quote} className='mix-blend-multiply absolute w-[70px] h-[70x] lg:w-[120px] left-[-6%] top-[2%] lg:left-[-8%] lg:top-[10%] opacity-[80%]' alt='Testimonial'></img>
        <div className="mb-4 h-[300px] lg:h-[400px] flex py-5 lg:py-20">
          <p className="text-md lg:text-xl leading-2 lg:leading-10">{testimonialData[currentTestimonial].text}</p>
        </div>
        <div className='bg-gray-500 w-[100px] h-[1px]'></div>
        <div className="flex justify-between h-[80px]">
            <div className="flex gap-2">
              <img
                src={testimonialData[currentTestimonial].image}
                className="rounded-full w-[50px] h-[50px]"
                alt={testimonialData[currentTestimonial].company}
              ></img>
              <div>
                <p className="text-sm lg:text-md font-bold">
                  {testimonialData[currentTestimonial].author}
                </p>
                <p className="text-sm">
                  {testimonialData[currentTestimonial].designation},{' '}
                  {testimonialData[currentTestimonial].company}
                </p>
              </div>
            </div>
          <div className="flex gap-2 lg:gap-4 items-start">
            <div
            name='Left Icon'
              onClick={handlePrevTestimonial}
              className="px-3 py-1 cursor-pointer w-[30px] h-[30px] lg:w-[40px] lg:h-[40px] flex items-center justify-center text-white bg-[#174759] rounded-full hover:brightness-[150%] focus:outline-none"
            >
              <KeyboardBackspaceIcon style={{ fontSize: '20px'}} />
            </div>
            <div
            name='Right Icon'
              onClick={handleNextTestimonial}
              style={{ transform: 'scaleX(-1)' }}
              className="px-3 py-1 cursor-pointer w-[30px] h-[30px] lg:w-[40px] lg:h-[40px] flex items-center justify-center rounded-full text-white flex-row-reverse bg-[#174759] rounded-full hover:brightness-[150%] focus:outline-none"
            >
              <KeyboardBackspaceIcon style={{ fontSize: '20px'}}/>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Testimonials
