import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { useState } from 'react';

import itp from '../assets/images/clients/itp.png';
import lalola from '../assets/images/clients/lalola.webp';
import ets from '../assets/images/clients/ets.webp';
import shipmyparcel from '../assets/images/clients/shipmyparcel.webp';
import concepts from '../assets/images/clients/concepts.svg';
import homecare from '../assets/images/clients/homecare.png';
import teamcardelight from '../assets/images/clients/teamcardelight.webp';
import evenlay from '../assets/images/clients/evenlay.jpeg';

const Clients = () => {
  const clients = [
    { company: 'ITPEnergised', image: itp },
    { company: 'lalola', image: lalola },
    { company: 'Evolved Transportation Systems', image: ets },
    { company: 'Evenlay', image: evenlay },
    { company: 'Concepts', image: concepts },
    { company: 'Team Car Delight', image: teamcardelight },
    { company: 'Homecare Detergents', image: homecare },
    { company: 'ShipMyParcel', image: shipmyparcel },
  ];

  const [settings] = useState({
    dots: false,
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    speed: 4000,
    autoplaySpeed: 4000,
    cssEase: 'linear',
    arrows: false,
  });

  return (
    <div className="flex flex-col items-center py-10">
      <p className="text-xs md:text-lg lg:text-lg">Trusted by clients worldwide</p>
      <div className="hidden lg:flex flex-wrap lg:flex-row lg:gap-14 py-10 lg:px-10">
        {clients.map((client, index) => (
          <div key={index} className="w-1/2 p-5 lg:p-0 lg:w-fit cursor-pointer">
            <img
              src={client.image}
              alt={client.company}
              className="aspect-auto w-full h-[60px] lg:h-[60px] object-contain grayscale-200 mix-blend-multiply transition-transform duration-500 ease-in-out transform hover:grayscale-0"
            />
          </div>
        ))}
      </div>
      <div className="block w-full py-10 mix-blend-multiply lg:hidden">
        <Slider {...settings}>
          {clients.map((client, index) => (
            <div key={index} className="w-fit">
              <img
                src={client.image}
                alt={client.company}
                className="aspect-auto w-full h-[60px] lg:h-[60px] object-contain grayscale-200 mix-blend-multiply transition-transform duration-500 ease-in-out transform hover:grayscale-0"
              />
            </div>
          ))}
        </Slider>
      </div>
    </div>
  );
};

export default Clients;
