export default function Footer() {
  return (
    <footer className="bottom-0 p-6 lg:px-32 bg-[#40E0D0] text-white">
      <div className="md:flex md:justify-between">
        <div className="mb-6 md:mb-0">
          <a href="/" className="flex items-center w-fit">
            <span className="self-center text-center lg:text-justify text-3xl whitespace-nowrap text-white">
              FUTURESTIC
            </span>
          </a>
        </div>
        <div className="grid grid-cols-2 gap-8 sm:gap-6 sm:grid-cols-3">
          <div>
            <h2 className="mb-6 text-sm font-semibold  uppercase text-white">
              Links
            </h2>
            <ul className="text-gray-600 text-white">
              <li className="mb-4">
                <a href="/" className="hover:underline">
                  Home
                </a>
              </li>
              <li className="mb-4">
                <a href="#services" className="hover:underline">
                  Services
                </a>
              </li>
              <li className="mb-4">
                <a href="#openasapp" className="hover:underline">
                  OpenAsApp Partner Program
                </a>
              </li>
              <li>
                <a href="#contact" className="hover:underline">
                  Contact
                </a>
              </li>
            </ul>
          </div>
          <div>
            <h2 className="mb-6 text-sm font-semibold  uppercase text-white">
              Follow us
            </h2>
            <ul className="text-gray-600 text-white">
              <li className="mb-4">
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://www.linkedin.com/company/71311453/"
                  className="hover:underline "
                >
                  LinkedIn
                </a>
              </li>
              <li>
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://github.com/orgs/FuturesticCo/"
                  className="hover:underline"
                >
                  Github
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <hr className="my-6 border-gray-200 sm:mx-auto border-gray-700 lg:my-8"></hr>
      <div className="sm:flex sm:items-center sm:justify-between">
        <span className="text-sm text-gray-500 sm:text-center text-white">
          © 2022{' '}
          <a href="/" className="hover:underline">
            Futurestic™
          </a>
          . All Rights Reserved.
        </span>
      </div>
    </footer>
  )
}
