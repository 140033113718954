import { PopupButton } from 'react-calendly'

export default function FloatingButton() {
  return (
    <div className="w-full p-5 border-[0.5px] z-50 bg-white shadow-md text-center block fixed bottom-0 lg:hidden">
      <PopupButton
        className="text-[#40E0D0] text-xl"
        rootElement={document.getElementById('root')}
        url="https://calendly.com/futurestic/discovery-call"
        text="Get Free Project Analysis"
      />
    </div>
  )
}
